var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "container" }, [
      _vm.ticketObj.title
        ? _c(
            "div",
            { staticClass: "title", staticStyle: { "margin-bottom": "10px" } },
            [_vm._v(_vm._s(_vm.ticketObj.title))]
          )
        : _vm._e(),
      _vm.ticketObj.debtPayUrl
        ? _c("img", {
            staticClass: "code",
            attrs: { src: require("../imgs/code.png"), alt: "" },
          })
        : _vm._e(),
      _vm.ticketObj.scanCodeInfo
        ? _c("div", { staticClass: "tip" }, [
            _vm._v(_vm._s(_vm.ticketObj.scanCodeInfo)),
          ])
        : _vm._e(),
      _vm.ticketObj.plateNumber
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("车牌号码：")]),
            _c("span", { staticClass: "info" }, [_vm._v("京A12345")]),
          ])
        : _vm._e(),
      _vm.ticketObj.plateColor
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("车牌颜色：")]),
            _c("span", { staticClass: "infos" }, [_vm._v("蓝色")]),
          ])
        : _vm._e(),
      _vm.ticketObj.parkName
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("停放路段：")]),
            _c("span", { staticClass: "infos" }, [
              _vm._v("这里显示停放路段名称"),
            ]),
          ])
        : _vm._e(),
      _vm.ticketObj.berthCode
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("停放泊位：")]),
            _c("span", { staticClass: "infos" }, [
              _vm._v("这里显示停车泊位编号"),
            ]),
          ])
        : _vm._e(),
      _vm.ticketObj.entryTime
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("入场时间：")]),
            _c("span", { staticClass: "infos" }, [
              _vm._v("xxxx-xx-xx xx：xx：xx"),
            ]),
          ])
        : _vm._e(),
      _vm.ticketObj.exitTime
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("出场时间：")]),
            _c("span", { staticClass: "infos" }, [
              _vm._v("xxxx-xx-xx xx：xx：xx"),
            ]),
          ])
        : _vm._e(),
      _vm.ticketObj.duration
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("停车时长：")]),
            _c("span", { staticClass: "infos" }, [_vm._v("这里显示停车时长")]),
          ])
        : _vm._e(),
      _vm.ticketObj.totalMoney
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("订单金额：")]),
            _c("span", { staticClass: "infos" }, [_vm._v("20.00元")]),
          ])
        : _vm._e(),
      _vm.ticketObj.payMoney
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("已付金额：")]),
            _c("span", { staticClass: "infos" }, [_vm._v("-已付金额")]),
          ])
        : _vm._e(),
      _vm.ticketObj.parkDiscountMoney
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("停车场折扣：")]),
            _c("span", { staticClass: "infos" }, [_vm._v("-停车场折扣金额")]),
          ])
        : _vm._e(),
      _vm.ticketObj.parkCardMoney
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("停车卡抵扣：")]),
            _c("span", { staticClass: "infos" }, [_vm._v("-停车卡抵扣金额")]),
          ])
        : _vm._e(),
      _vm.ticketObj.couponMoney
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("优惠券：")]),
            _c("span", { staticClass: "infos" }, [_vm._v("-优惠券金额")]),
          ])
        : _vm._e(),
      _vm.ticketObj.refundMoney
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("退款金额：")]),
            _c("span", { staticClass: "infos" }, [_vm._v("这里显示退款金额")]),
          ])
        : _vm._e(),
      _vm.ticketObj.money
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("还需支付：")]),
            _c("span", { staticClass: "infos" }, [
              _vm._v("这里显示还需支付金额"),
            ]),
          ])
        : _vm._e(),
      _vm.ticketObj.operationName
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("运营单位：")]),
            _c("span", { staticClass: "infos" }, [
              _vm._v("智慧互通科技股份有限公司"),
            ]),
          ])
        : _vm._e(),
      _vm.ticketObj.pdaManagerCode
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("收费员编号：")]),
            _c("span", { staticClass: "infos" }, [
              _vm._v("这里显示收费员编号"),
            ]),
          ])
        : _vm._e(),
      _vm.ticketObj.remark || _vm.ticketObj.printTime
        ? _c("div", { staticClass: "line" })
        : _vm._e(),
      _vm.ticketObj.remark
        ? _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("温馨提示：")]),
            _c("span", { staticClass: "infos" }, [
              _vm._v(_vm._s(_vm.ticketObj.remark)),
            ]),
          ])
        : _vm._e(),
      _vm.ticketObj.printTime
        ? _c("div", { staticClass: "item last_item" }, [
            _c("span", { staticClass: "label time" }, [_vm._v("打印时间: ")]),
            _c("span", { staticClass: "infos" }, [
              _vm._v("xxxx-xx-xx xx：xx：xx"),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }